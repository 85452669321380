<template>
  <div class="pages p20">
    <div class="title-btn-block">
      <div class="title-block">{{$t('message.complaint')}}</div>
      <div>
        <el-button class="my-btn " type="warning" @click="take" round>
          {{$t('message.send_complaint')}}
        </el-button>
        <router-link :to="{ name: 'complaintTable' }">
          <div class="my-btn cr1 el-button ml20">
            <i class="el-icon-d-arrow-left"></i> {{$t('message.back')}}
          </div>
        </router-link>
      </div>
    </div>
    
    <div class="content-block">
      <div class="datetime-end bg p20 mb20">
        <el-divider content-position="left"> {{$t('message.manager')}}</el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="seloction mb20">
              <el-select
                v-model="form.manager_id"
                :placeholder="$t('message.manager')"
                class="w-100"
                popper-class="my-data-khan"
              >
                <el-option
                  :value="list.manager.id"
                  :key="'guestmanager-' + index"
                  :label="
                    list.manager
                      ? list.manager.name +
                        ' ' +
                        list.manager.last_name +
                        ' ' +
                        list.manager.middle_name
                      : ''
                  "
                ></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="comment bg p20 mb20">
        <el-divider content-position="left"> {{$t('message.complaint')}} </el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-input
              :placeholder="$t('message.title')"
              v-model="form.header"
              class="mb20"
            ></el-input>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-input type="textarea" v-model="form.title" :placeholder="$t('message.comment')"></el-input>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { getItem } from "@/utils/storage";

import { mapGetters, mapActions } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      region: "",
      value1: "",
      value: "",
      form: {},
      list: [],
    };
  },

  mounted() {
    this.setList();
  },
  methods: {
    ...mapActions({
      updateList: "complaint/store",
      getList: "assent/index",
    }),
    setList() {
      this.getList(getItem("userId")).then((res) => {
        this.list = res.data.result.data.staff;
      });
    },
    take() {
      this.form.staff_id = getItem("userId");
      this.updateList(this.form)
        .then((res) => {
          if (res.status == 201) {
            this.$alert(res);
            let route = this.$router.push({
              name: "Home",
              params: {
                id: this.list.id,
              },
            });
            window.open(route.href, "_self");
          } else {
            this.$alert(res);
          }
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
  },
};
</script>
